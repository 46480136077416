import { useEffect, useState } from 'react';
import {
  LoginForm as PageLoginForm,
  ForgotPasswordLink,
} from '@hungryroot/page-login';
import { islandRoot } from '../IslandRoot/IslandRoot';

import styles from './LoginFormIsland.module.css';

function LoginForm() {
  const [className, setClassName] = useState(styles.loading);

  // don't show the form until the client has loaded
  useEffect(() => {
    setClassName('');
  }, []);

  return (
    <PageLoginForm
      className={className}
      onLoginSuccess={({ next }) => {
        window.location.href = next;
      }}
    />
  );
}

export const LoginFormIsland = islandRoot(LoginForm);

export function ForgotPasswordIsland() {
  const [urlSearchParams, setSearchParams] = useState<
    URLSearchParams | undefined
  >(undefined);

  useEffect(() => {
    setSearchParams(new URLSearchParams(window.location.search));
  }, []);

  return (
    <ForgotPasswordLink
      className={urlSearchParams === undefined ? styles.loading : undefined}
      urlSearchParams={urlSearchParams}
    />
  );
}
